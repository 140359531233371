import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import type { FromShippingItem } from 'src/types/manageWorkOrder'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export function useShippingItems(itemId: number) {
  const API_ENDPOINT = `/workorders/${itemId}/shipping`
  return useQuery('fromShippingItems', async () => {
    return await fetchWrapper<FromShippingItem[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}

export function useUpdateShippingItems(onSuccessCallback: (isSuccess: boolean) => void) {
  
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  
  const updateShipping = async (data: any) => {
    try {
      
      const API_ENDPOINT = data?.id ? `/workorders/shipping/${data?.id}/update`  :  `/dropShippingAddress/update`
        const response = await fetchWrapper({
          method: 'PUT',
          url: API_ENDPOINT,
          body: data?.items,
        })
        toast.success(t('Shipping items updated successfully'))
        return response
      } catch (error) {
        
        throw error
      }
    }
  
    return useMutation('updateShippingItems', updateShipping, {
      onSuccess: () => {
        queryClient.invalidateQueries('updateShippingItems');
        onSuccessCallback(true);
      },
      onError: (error) => {
        console.error('API request failed:', error)
        toast.error(t('Failed to update shipping items'))
        onSuccessCallback(false);
      },
    })
  }
