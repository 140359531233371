import { Box, Grid, Tooltip } from "@mui/material";
import { InfoSection } from "src/components/common";
import { useTranslation } from "react-i18next";
import useGoogleTranslation from "src/services/useGoogleTranslation";

type DecorationsCardProps = {
  data: any;
  onClick: (row: string) => void;
  stopAutoPlay: boolean;
  setStopAutoPlay: Function;
  setClickedRowIndex: Function;
  clickedRowIndex: number | null;
  index: number;
};

function DecorationCard({
  data,
  onClick,
  stopAutoPlay,
  setStopAutoPlay,
  setClickedRowIndex,
  clickedRowIndex,
  index,
}: DecorationsCardProps) {
  const { t } = useTranslation();
  const DecorationsType = useGoogleTranslation(data?.DecorationsType);
  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(data?.ArtDescriptionLink);
    setStopAutoPlay(true);
    setClickedRowIndex(index);
  };

  return (
    <Box
      onClick={handleRowClick}
      component="div"
      sx={{
        backgroundColor: "var(--color-gray-light)",
        minHeight: "100px",
        marginX: "auto",
        paddingY: "9px",
        borderRadius: "15px",
        paddingX: "24px",
      }}
    >
      <Grid container sx={{ height: "100%", alignItems: "flex-start" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          display={"flex"}
          alignItems={"space-between"}
          justifyContent={"center"}
          flexDirection={"column"}
          rowGap={1.4}
        >
          <InfoSection
            title={`${t("design sku")} :`}
            value={data?.DesignSKU}
            doNotChangeCase={true}
          />
          <InfoSection
            title={`${t("design id")} :`}
            value={data?.DesignId}
            doNotChangeCase={true}
          />
          <InfoSection title={`${t("dec type")} :`} value={DecorationsType} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          display={"flex"}
          alignItems={"space-between"}
          justifyContent={"center"}
          flexDirection={"column"}
          rowGap={1.4}
        >
          <InfoSection
            title={`${t("dec loc")} :`}
            value={data?.Location}
            doNotChangeCase={true}
          />
          <InfoSection
            title={`${t("# of artifacts")} :`}
            value={data?.NumberOfArtifacts}
          />
          <InfoSection
            title={`${t("billable colors")} :`}
            value={data?.BillableColors}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box>
            <InfoSection
              title={`${t("colors")} :`}
              value={data?.Colors?.map((el: any) => (
                <Box display={"block"} component="span" sx={{ cursor: "help" }}>
                  <Tooltip
                    title={el?.KeySecondary}
                    placement="top"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}

                  >
                    {el?.KeyValue}
                  </Tooltip>
                </Box>
              ))}
              direction={"column"}
              rowGap={0}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DecorationCard;
