import React from "react";
import { Grid, Box } from "@mui/material";
import { Text } from "src/components/common";
// import { capitalizedCase } from "src/utils/capitalizedCase";

type CardSubContentProps = {
  title: string;
  value?: string | number;
  icon?: React.ReactNode;
  width?: string;
  valueUpperCase?: boolean;
  noWrap?: boolean;
  valuesArr?: any;
  align?: any;
};

const ShippingItemContent = ({
  title,
  value,
  width = "100%",
  valueUpperCase = false,
  align = 'left',
  noWrap = false,
  valuesArr=[],
}: CardSubContentProps) => {
  return (
    <Grid item maxWidth={width} padding={0} margin={0}>
      <Text
        fontStyle="normal normal 600 14px/14px Poppins"
        align={align}
        opacity={0.5}
        typographyProps={{ padding: 0, marginBottom: "2px" }}
        upperCase={true}
        noWrap={noWrap}
      >
        {title}
      </Text>
      <div>
        {valuesArr && (
          <Box sx={{textAlign: align, width: '100%'}}>
            {valuesArr?.map((el: any, ind: number) => (
              <Text
                fontStyle="normal normal medium 18px/18px Poppins"
                align={align}
                typographyProps={{ padding: 0 }}
                noWrap={noWrap}
                key={`${ind}_${el}`}
              >
                {el}
              </Text>
            ))}{" "}
          </Box>
        )}
        {value && (
          <Text
            fontStyle="normal normal medium 18px/18px Poppins"
            align={align}
            typographyProps={{ padding: 0 }}
            noWrap={noWrap}
          >
            {value || '--'}
          </Text>
        )}
      </div>
    </Grid>
  );
};

export default ShippingItemContent;
