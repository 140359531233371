import React from "react";
import {
  Box,
  Checkbox,
  Grid,
} from "@mui/material";
import Text from "../../../common/Text";
import { ErrorMessage, LoadingSpinner } from "src/components/common";
import { useTranslation } from "react-i18next";
import type {
  FromShippingItem,
} from "src/types/manageWorkOrder";
import ShippingItemContent from "./ShippingItemContent";
import { useShippingItems } from "src/hooks/ManageWorkOrder";

type WorkOrderTablesProps = {
  workOrderId: number;
  handleChecked: (arg1: any, arg2: boolean) => void,
  checkedItems: any,
  isCore: boolean
};

type FromShippingItemsResults = {
  data: FromShippingItem[];
  isLoading: boolean;
  error: string | null;
};

export type CellProps = {
  title: string | React.ReactNode;
  align?: "left" | "center" | "right";
};

// const HeaderCell = ({ title, align = "center" }: CellProps) => {
//   return (
//     <TableCell
//       sx={{ padding: "5px 0", margin: 0, borderBottom: "1px solid #808080" }}
//     >
//       <Text
//         align={align}
//         fontStyle="normal normal 600 14px/14px Poppins;"
//         opacity={0.5}
//         upperCase={true}
//       >
//         {title}
//       </Text>
//     </TableCell>
//   );
// };

function ShippingItems({ workOrderId, handleChecked, checkedItems, isCore }: WorkOrderTablesProps) {
  const {
    data: ShippingItem,
    isLoading,
    error,
  } = useShippingItems(workOrderId) as FromShippingItemsResults;

  const { t } = useTranslation();
  // const columns = [
  //   t("edi-po number"),
  //   t("shipping information"),
  //   t("description"),
  //   t("color"),
  //   t("size"),
  //   t("quantity"),
  //   t("shipped"),
  // ];

  const centerAlign = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }


  const shippingInfo = (item: any) => {
    const variables = [item?.Method, item?.Name, item?.CompanyName, item?.StreetAddress, item?.StreetAddress_secondary, item?.City, item?.State, item?.Zip];

    // Filter out null or undefined values and join with comma
    const result = variables.filter(Boolean).join(', ');
    return result
  }

  // const getData = () => {
  //   queryClient.removeQueries('fromShippingItems');

  // }

  // useEffect(() => {
  //   getData()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  

  
  return (
    <>
      {isLoading ? (
        <LoadingSpinner height="30vh" width="100%" />
      ) : error ? (
        <ErrorMessage message={`${error}`} />
      ) : ShippingItem?.length <= 0 ? (
        <ErrorMessage message={`No data found`} />
      ) : (
        ShippingItem?.map((row, i) => (
          <Box
            sx={{
              backgroundColor: "var(--color-dark-gray)",
              minHeight: "100px",
              paddingY: "9px",
              borderRadius: "15px",
              paddingX: "16px",
              marginBottom: 1,
              marginX: 1
            }}
            key={row?.Id}
          >
            <Grid container justifyContent={'space-between'}>
              {!isCore && <Grid item  sx={{...centerAlign}}>
                <ShippingItemContent
                  title={t("edi-po number")}
                  value={ row?.DS_PONumber ?? "--"}
                  noWrap={true}
                />
              </Grid>}
              <Grid item >
                <ShippingItemContent
                  title={t("shipping information")}
                  value={shippingInfo(row) ?? "--"}
                  width="200px"
                  noWrap={false}
                />
              </Grid>
              <Grid item >
                <ShippingItemContent
                  title={t("description")}
                  valuesArr={row?.WorkOrderItems?.map(el => el?.CatalogStyle) ?? 
                    ['--']
                    }
                  width="100%"
                  noWrap={false}
                />
              </Grid>
              <Grid item  >
                <ShippingItemContent
                  title={t("color")}
                  valuesArr={row?.WorkOrderItems?.map(el => el?.CatalogColor) ?? 
                    ['--']
                    }
                  width="100%"
                  noWrap={false}
                  align='center'
                />
              </Grid>
              <Grid item >
                <ShippingItemContent
                  title={t("size")}
                  valuesArr={row?.WorkOrderItems?.map(el => el?.CatalogSize) ?? 
                    ['--']
                    }
                  width="100%"
                  noWrap={false}
                  align='center'
                />
              </Grid>
              <Grid item>
                <ShippingItemContent
                  title={t("quantity")}
                  valuesArr={row?.WorkOrderItems?.map(el => el?.Quantity) ?? 
                  ['--']
                  }
                  width="100%"
                  noWrap={false}
                  align='center'
                />
              </Grid>
              <Grid item flexDirection={'column'} display='flex' alignItems={'center'}>
                <Text
                  fontStyle="normal normal 600 14px/14px Poppins"
                  opacity={0.5}
                  typographyProps={{ padding: 0, marginBottom: "2px" }}
                  upperCase={true}
                  noWrap={false}
                  align='center'
                >
                  {"Shipped"}
                </Text>
                <Checkbox  sx={{
                  color: '#FFF',
                  padding: '4px',
                  '& .MuiSvgIcon-root': {
                    font: 'normal normal normal 30px/14px Poppins',
                  },
                }}
                checked={(row?.Shipped === 1) || (checkedItems?.find((el: any) => el?.Id === row?.Id))}
                onChange={e => row?.Shipped === 0 && handleChecked(row, e.target.checked)}
                />
              </Grid>
            </Grid>
          </Box>
        ))
      )}

    </>
  );
}

export default ShippingItems;
