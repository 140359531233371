import React from 'react'
import { Grid } from '@mui/material'
import {
  TaskDetailsTopContainer,
  TasksCardContainer,
  TasksButtonsContainer,
  StageCompleteDialog,
} from 'src/components/PipelineTasks'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDialog } from 'src/hooks/common'
import { HelmetComponent } from 'src/components/common'
import { LoadingSpinner, ErrorMessage } from 'src/components/common'
import WorkDoneDialog from 'src/components/ManageWorkOrder/Dialogs/WorkDoneDialog'
import {
  useTaskUpdate,
  useStageUpdate,
  useCreateWorkOrderActivity,
} from 'src/hooks/PipelineTasks'
import { useWorkOrderDetails } from 'src/hooks/ManageWorkOrder'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WorkOrdeItemResult = {
  data: WorkOrderItem[]
  error: string | null
  isLoading: boolean
}

const PipelineTasksPage = () => {
  const [isClicked, setIsClicked] = React.useState<boolean>(false)
  const [selectedTaskID, setSelectedTaskID] = React.useState<number | null>(
    null
  )
  const [selectedTaskOrder, setSelectedTaskOrder] = React.useState<number>(0)
  const navigate = useNavigate()

  const [lastOrderId, setLastOrderId] = React.useState<number>(0)
  const { isOpen, openDialog, closeDialog } = useDialog()
  const {
    isOpen: isWorkDoneDialogOpen,
    closeDialog: closeWorkDoneDialog,
    openDialog: openWorkDoneDialog,
  } = useDialog()

  React.useEffect(() => {
    if (isOpen) {
      setIsClicked(false)
    }
  }, [isOpen])

  const location = useLocation()
  const orderDetails = location.state?.orderDetails
  const workOrderId = orderDetails?.WorkOrderID

  const { mutate: UpdateStage } = useStageUpdate(workOrderId)

  const { mutate: UpdateTask } = useTaskUpdate(workOrderId)

  const { mutate: createActivity } = useCreateWorkOrderActivity(workOrderId)

  const {
    data: workOrderItem,
    isLoading,
    error,
  } = useWorkOrderDetails(workOrderId) as WorkOrdeItemResult
  if (isLoading) return <LoadingSpinner height={'80vh'} />
  if (error) return <ErrorMessage message={`${error}`} />
  const CurrentStageOrder = workOrderItem?.[0]?.CurrentStageOrder
  const TotalStages = workOrderItem?.[0]?.TotalStages
  const workToBeDoneID = workOrderItem[0]?.Stage_WTBDID
  const StageID = workOrderItem?.[0]?.StageId
  const CustomerId = workOrderItem?.[0]?.CustomerId
  const PO_Type =workOrderItem?.[0]?.PO_Type



  function handleModalClose() {
    setSelectedTaskID(null)
    closeDialog()
  }

  const handleUpdateTask = () => {
    if (!selectedTaskID) {
      return
    }
    if (lastOrderId === selectedTaskOrder) {
      openDialog()
    } else {
      const data = { stage_wtbdId: selectedTaskID }
      UpdateTask(data)
      createActivity({
        stageId: StageID + 1,
        stage_wtbdId: selectedTaskID,
      })
      handleModalClose()
    }
  }

  const handleCompleteTask = () => {
    if (CurrentStageOrder === TotalStages) {
      const data = { stage_wtbdId: selectedTaskID }
      UpdateTask(data)

      
      if(PO_Type === 'DropShipping'){
        closeDialog();
        navigate('/work-order-summary')
      }else{
        openWorkDoneDialog()
      }
    } else {
      UpdateStage({ stageId: StageID + 1 })
      const data = { stage_wtbdId: selectedTaskID }
      UpdateTask(data)
      createActivity({
        stageId: StageID + 1,
        stage_wtbdId: selectedTaskID,
      })
      handleModalClose()
      navigate('/work-order-summary')
    }
  }

  return (
    <>
      <HelmetComponent
        title='Manage Pipeline Tasks | Silva Production'
        description='description'
        content='Manage Pipeline Tasks | Silva Production'
      />
      <Grid
        item
        container
        direction='column'
        height='100%'
        justifyContent='space-between'
      >
        <TaskDetailsTopContainer workOrderItem={workOrderItem} />
        <TasksCardContainer
          setSelectedTaskID={setSelectedTaskID}
          StageID={StageID}
          workToBeDoneID={workToBeDoneID}
          onLastOrderIdChange={setLastOrderId}
          setSelectedTaskOrder={setSelectedTaskOrder}
          CustomerId={CustomerId}
          workOrderId={workOrderId}
          CurrentStageOrder={CurrentStageOrder}
          TotalStages={TotalStages}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
        />
        <TasksButtonsContainer
          selectedTaskID={selectedTaskID}
          onComplete={handleUpdateTask}
        />
      </Grid>
      <StageCompleteDialog
        isOpen={isOpen}
        closeDialog={handleModalClose}
        onPrimaryButtonClick={handleCompleteTask}
      />
      <WorkDoneDialog
        isOpen={isWorkDoneDialogOpen}
        closeDialog={closeWorkDoneDialog}
        workOrderId={workOrderId}
      />
    </>
  )
}

export default PipelineTasksPage
