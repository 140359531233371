import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Text from '../../common/Text'
import NotesTable from '../WorkOrderTables/NotesTable'
import DecorationsTable from '../WorkOrderTables/DecorationsTable'
import InventoryTable from '../WorkOrderTables/InventoryTable'
import { useTranslation } from 'react-i18next'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WorkOrderTablesProps = {
  workOrderItem: WorkOrderItem[]
  workOrderId: number
  onRowClick: (row: string) => void
  stopAutoPlay: boolean
  setStopAutoPlay: Function
}

const activeStyle = {
  borderBottom: '5px solid white',
}
const inActiveStyle = {
  borderBottom: '2px solid white',
  opacity: 0.5,
}

const TABS_TAGS = {
  INVENTORY: 'inventory',
  DECORATIONS: 'decorations',
  NOTES: 'notes',
}

function WorkOrderTables({
  workOrderItem,
  workOrderId,
  onRowClick,
  stopAutoPlay,
  setStopAutoPlay,
}: WorkOrderTablesProps) {
  const [value, setValue] = React.useState(TABS_TAGS.INVENTORY)
  const { t } = useTranslation()

  const handleChange = (newValue: string) => {
    setValue(newValue)
  }

  const renderTab = React.useMemo(() => {
    const handleDecorationsRowClick = (imageUrl: string) => {
      onRowClick(imageUrl)
    }
    switch (value) {
      case TABS_TAGS.INVENTORY:
        return (
          <InventoryTable
            workOrderItem={workOrderItem}
            workOrderId={workOrderId}
          />
        )
      case TABS_TAGS.DECORATIONS:
        return (
          <DecorationsTable
            workOrderId={workOrderId}
            onRowClick={handleDecorationsRowClick}
            stopAutoPlay={stopAutoPlay}
            setStopAutoPlay={setStopAutoPlay}
          />
        )
      case TABS_TAGS.NOTES:
        return <NotesTable workOrderId={workOrderId} />
      default:
        ;<></>
    }
  }, [
    value,
    workOrderItem,
    workOrderId,
    onRowClick,
    stopAutoPlay,
    setStopAutoPlay,
  ])
  

  return (
    <Grid
      item
      sx={{ width: '60%' }}
    >
      <Card sx={{ height: '100%' }}>
        <Tabs
          value={value}
          onChange={(_, value) => handleChange(value)}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            padding: '13px',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-between',
              paddingRight: 5,
              gap: 4,
            },
            '& .MuiTab-root': {
              padding: 0,
              flex: 1,
              alignItems: 'flex-start',
            },
          }}
          textColor='inherit'
        >
          <Tab
            sx={value === TABS_TAGS.INVENTORY ? activeStyle : inActiveStyle}
            value={TABS_TAGS.INVENTORY}
            label={
              <Text
                upperCase={true}
                align='left'
              >
                {t('From inventory')}
              </Text>
            }
          />
        {workOrderItem?.[0]?.PO_Type !== 'DropShipping' &&  <Tab
            sx={value === TABS_TAGS.DECORATIONS ? activeStyle : inActiveStyle}
            value={TABS_TAGS.DECORATIONS}
            label={
              <Text
                upperCase={true}
                align='left'
              >
                {t('decorations')}
              </Text>
            }
          />}
          <Tab
            sx={value === TABS_TAGS.NOTES ? activeStyle : inActiveStyle}
            value={TABS_TAGS.NOTES}
            label={
              <Text
                upperCase={true}
                align='left'
              >
                {t('work order notes')}
              </Text>
            }
          />
        </Tabs>
        <CardContent sx={{ margin: 0, padding: 0 }}>{renderTab}</CardContent>
      </Card>
    </Grid>
  )
}

export default WorkOrderTables
