import React from 'react'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import {
  AddNotesDialog,
  ShippingItemDialog,
  WorkOrderButtons,
  WorkOrderDashboard,
  WorkOrderDetails,
} from 'src/components/ManageWorkOrder'
import { useDialog } from 'src/hooks/common'
import { useWorkOrderDetails } from 'src/hooks/ManageWorkOrder'
import {
  ErrorMessage,
  HelmetComponent,
  LoadingSpinner,
} from 'src/components/common'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WorkOrdeItemResult = {
  data: WorkOrderItem[]
  error: string | null
  isLoading: boolean
}

function ManageWorkOrder() {
  const [initialFlagChecked, setInitialFlagChecked] = React.useState(false)
  const [stopAutoPlay, setStopAutoPlay] = React.useState<boolean>(false)
  const location = useLocation()
  const orderDetails = location.state?.orderDetails
  const workOrderId = orderDetails?.WorkOrderID
  const {
    isOpen: isAddNoteDialogOpen,
    closeDialog: closeAddNoteDialogOpen,
    openDialog: openAddNoteDialogOpen,
  } = useDialog()
  const {
    isOpen: isShippingItemDialogOpen,
    closeDialog: closeShippingItemDialogOpen,
    openDialog: openShippingItemDialogOpen,
  } = useDialog()
  const {
    data: workOrderItem,
    error,
    isLoading,
  } = useWorkOrderDetails(workOrderId) as WorkOrdeItemResult
  const flagged = workOrderItem?.[0]?.Flagged
  return (
    <>
      <HelmetComponent
        title='Manage Work Order | Silva Production'
        description='description'
        content='Manage Work Order | Silva Production'
      />
      <Grid
        container
        gap={2}
        direction='column'
        padding='20px 0'
        sx={{ width: '1318px' }}
        onClick={() => setStopAutoPlay(false)}
      >
        <WorkOrderButtons
          openAddNotesDialog={(initialFlagChecked) => {
            openAddNoteDialogOpen()
            setInitialFlagChecked(initialFlagChecked)
          }}
          openShippingItemDialog={ () =>{
            openShippingItemDialogOpen();
          }
          }
          orderDetails={orderDetails}
        />

        {isLoading ? (
          <LoadingSpinner
            height='70vh'
            width='125vh'
          />
        ) : error ? (
          <ErrorMessage message={`${error}`} />
        ) : (
          <>
            <WorkOrderDetails workOrderItem={workOrderItem} />
            <WorkOrderDashboard
              workOrderItem={workOrderItem}
              workOrderId={workOrderId}
              stopAutoPlay={stopAutoPlay}
              setStopAutoPlay={setStopAutoPlay}
            />
          </>
        )}
        <AddNotesDialog
          isOpen={isAddNoteDialogOpen}
          closeDialog={closeAddNoteDialogOpen}
          workOrderId={workOrderId}
          flagged={flagged}
          initialFlagChecked={initialFlagChecked}
        />
        <ShippingItemDialog
         isOpen={isShippingItemDialogOpen}
         closeDialog={closeShippingItemDialogOpen}
         workOrderId={workOrderId}
         workOrderItem={workOrderItem?.[0]}
        />
      </Grid>
    </>
  )
}

export default ManageWorkOrder
