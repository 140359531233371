import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import type { workOrderPipleline } from 'src/types/workOrderPipeline'

const API_ENDPOINT = '/workorders/active/list'

type WorkOrderResponse = {
    data: workOrderPipleline[]
    pagination: any
};

export function useLiveWorkOrder() {
  return useQuery('LiveWorkOrder', async () => {
    const res = await fetchWrapper<WorkOrderResponse>({
      method: 'GET',
      url: API_ENDPOINT,
      //additional header only for this api.
      headers: {
        Origin: 'https://staging.portal.silvaorder.com',
      },
    })
    return res.data || []
  })
}
