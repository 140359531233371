import React from 'react'
import { SvgIcon, TableCell, TableRow } from '@mui/material'
import Text from '../../../common/Text'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import type { CellProps } from './InventoryTable'

const RowCell = ({ title, align = 'center' }: CellProps) => {
  return (
    <TableCell
      sx={{
        padding: '5px 0',
        margin: 0,
        borderBottom: 'none',
      }}
    >
      <Text
        align={align}
        fontStyle='normal normal medium 14px/56px Poppins'
        noWrap={false}
      >
        {title}
      </Text>
    </TableCell>
  )
}

const InventoryRow = ({ row }: any, { i }: any) => {
  const GarmentDescription = useGoogleTranslation(row.GarmentDescription)
  const CatalogColor = useGoogleTranslation(row.CatalogColor)
  return (
    <TableRow key={i}>
      <RowCell title={row.CatalogStyle ?? '- -'} />
      <RowCell
        title={GarmentDescription ?? '- -'}
        align='center'
      />
      <RowCell title={CatalogColor ?? '- -'} />
      <RowCell title={row.CatalogSize ?? '- -'} />
      <RowCell title={row.Quantity ?? '- -'} />
      <RowCell
        title={
          <SvgIcon sx={{ color: 'white' }}>
            {row.SilvaProvided === 'YES' ? <CheckIcon /> : <CloseIcon />}
          </SvgIcon>
        }
      />
      <RowCell title={row.SKU ?? '- -'} />
    </TableRow>
  )
}

export default InventoryRow
