import React from 'react'
import type { WorkOrderSummaryDetails } from 'src/types/workOrderSummary'

type ManageInventorySearchBarProps = {
  setFiltered: React.Dispatch<React.SetStateAction<WorkOrderSummaryDetails[]>>
  dataset: WorkOrderSummaryDetails[]
}

function WorkOrderSummarySearchBar({
  setFiltered,
  dataset,
}: ManageInventorySearchBarProps) {
  const [search, setSearch] = React.useState<string>('')
  const [tags, setTags] = React.useState<string[]>([])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && search.trim() !== '') {
      setTags([...tags, search])
      setSearch('')
    } else if (event.key === 'Backspace' && search === '' && tags.length > 0) {
      const updatedTags = [...tags]
      updatedTags.pop()
      setTags(updatedTags)
    }
  }

  React.useMemo(() => {
    let filteredData = dataset

    const filterFields = [
      'WorkOrderID',
      'JobName',
      'CustomerPO',
      'AlternativePO',
      'DS_PONumber'
    ]

    tags.forEach((tag) => {
      const tagTerm = typeof tag === 'string' ? tag.toLowerCase() : String(tag)
      filteredData = filteredData?.filter((product) => {
        return (
          tagTerm === '' ||
          filterFields.some((field) => {
            const value = String((product as any)[field])
            if (field === 'DS_PONumber' && Array.isArray(value)) {
              return value.some((item) => String(item).toLowerCase().includes(tagTerm));
            }
            return value?.toLowerCase()?.includes(tagTerm)
          })
        )
      })
    })

    setFiltered(filteredData)
  }, [tags, dataset, setFiltered])

  return (
    <div style={tagContainerStyle}>
      <div style={tagInputContainerStyle}>
        {tags.map((tag, index) => (
          <div
            key={index}
            style={tagStyle}
          >
            {tag}
          </div>
        ))}
        <input
          placeholder=''
          type='text'
          value={search}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          style={tagInputStyle}
        />
      </div>
    </div>
  )
}

export default WorkOrderSummarySearchBar

const tagContainerStyle: React.CSSProperties = {
  display: 'flex',
  width: '500px',
  height: '40px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  overflow: 'hidden',
}

const tagStyle: React.CSSProperties = {
  backgroundColor: '#A9CE31',
  fontSize: '15px',
  color: 'black',
  padding: '4px 8px',
  borderRadius: '4px',
  margin: '2px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}

const tagInputContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '4px',
  whiteSpace: 'nowrap',
  overflowX: 'auto',
  width: '100%',
}

const tagInputStyle: React.CSSProperties = {
  flex: 1,
  fontSize: '15px',
  border: 'none',
  outline: 'none',
}
