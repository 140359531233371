import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { FilledButton } from './common/Buttons'
import { LogoIcon, TruckIcon, ShirtsIcon } from './common/svg'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/providers/AuthContext'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

function NavBar() {
  const { getSession, logout } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const idToken = useCookies(['id_token'])[0].id_token

  const isLoggedIn = !!idToken

  React.useEffect(() => {
    const fetchSession = async () => {
      try {
        await getSession()
      } catch (error) {
        console.error('Error getting session:', error)
      }
    }

    fetchSession()
  }, [getSession])

  const handleLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.error('Error logging out:', error)
    }
  }

  return (
    <Grid
      container
      height={100}
      alignItems='center'
      sx={{ backgroundColor: isLoggedIn ? '#2A323C' : '' }}
    >
      <Grid
        item={true}
        container={true}
        xs={1.7}
        paddingLeft={2}
        width={314}
        flexWrap='nowrap'
        alignItems='center'
        height='100%'
        sx={{ backgroundColor: isLoggedIn ? 'var(--color-gray-light)' : '' }}
        onClick={() => navigate('/')}
      >
        <LogoIcon />
      </Grid>
      {isLoggedIn && (
        <>
          <Grid
            item={true}
            container={true}
            xs={7.9}
            alignItems='center'
          >
            <Box
              pl={4}
              onClick={() => navigate('/work-order-summary')}
            >
              <ShirtsIcon />
            </Box>
            <Box
              pl={4}
              onClick={() => navigate('/')}
            >
              <TruckIcon />
            </Box>
          </Grid>
          <Grid
            item={true}
            xs={1.7}
          >
            <FilledButton
              onClick={handleLogout}
              sx={{
                padding: '13px 30px',
                height: 48,
                marginRight: '3px',
              }}
              textProps={{ fontStyle: 'normal normal bold 16px/14px Poppins' }}
            >
              {t('logout')}
            </FilledButton>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default NavBar
